import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { PrivateComponent } from './private.component';

// Modules
import { PrivateRoutingModule } from './private-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationModule } from '@configuration/configuration.module';
import { EquipmentsModule } from '@equipments/equipments.module';
import { CardsMgmtModule } from '@cards/cards-mgmt/cards-mgmt.module';
import { AlertsModule } from '@alerts/alerts.module';
import { CustomersMgmtModule } from '@customers/customers-mgmt/customers-mgmt.module';
import { MqttModule } from '@mqtt/mqtt.module';
import { SecurityModule } from '@security/security.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    PrivateComponent
  ],
  imports: [
    CommonModule,
    // RouterModule,
    // PrivateRoutingModule,

    SharedModule,

    // AlertsModule,
    // CardsMgmtModule,
    // ConfigurationModule,
    // CustomersMgmtModule,
    // EquipmentsModule,
    // MqttModule,
    // SecurityModule,
  ]
})
export class PrivateModule { }
