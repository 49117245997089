import { Pipe, PipeTransform } from '@angular/core';
import { getDeviceResetTypes } from '@enums/device-reset-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'DeviceResetTypePipe'
})
export class DeviceResetTypePipe implements PipeTransform {

  deviceResetTypes: any[] = [];

  constructor(private translateService: TranslateService) {}

  transform(value: unknown, arg1?: any[]): unknown {

    const idToBeSearched = value;

    if (arg1 && arg1?.length > 0)
      this.deviceResetTypes= arg1;
    else
      this.deviceResetTypes = getDeviceResetTypes();


    if (this.deviceResetTypes?.length>0) {

      const itemFounded = this.deviceResetTypes.find( item => item.value === idToBeSearched);
      if (itemFounded) return this.translateService.instant(itemFounded.name);
    }

    return this.translateService.instant('DEVICE_RESET_TYPE_ID_NOT_FOUND');
  }

}
