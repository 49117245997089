import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { NotFoundComponent } from './static/not-found/not-found.component';
import { PrivateComponent } from './private/private.component';
import { canAccessGuard } from './auth/guards/access.guard';

const routes: Routes = [
  {
    path: 'autenticacion',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'map',
    canActivate: [canAccessGuard],
    loadChildren: () => import('./status-map/status-map.module').then(m => m.StatusMapModule),
  },
  {
    path: '',
    component: PrivateComponent,
    canActivate: [canAccessGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        canActivate: [canAccessGuard],
        data: {
          roles: ['ROLE_ROOT', 'ROLE_MANAGER'],
        },
      },
      {
        path: 'estadisticas',
        loadChildren: () => import('./stats/stats.module').then(m => m.StatsModule),
      },
      {
        path: 'alertas',
        loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule),
      },
      {
        path: 'tarjetas',
        loadChildren: () => import('./cards/cards.module').then(m => m.CardsModule),
        canActivate: [canAccessGuard],
        data: {
          id: 'tarjetas',
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
      },
      {
        path: 'administracion',
        data: { headerData: { title: 'Administración' } },
        // canActivate: [canAccessGuard],
        children: [
          {
            path: 'seguridad',
            loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
            data: { headerData: { title: 'Seguridad' } }
          },
          {
            path: 'configuracion',
            loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
            data: { headerData: { title: 'Configuración' } }
          },
        ],
      },
      {
        path: 'clientes',
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
      },
      {
        path: 'equipos',
        loadChildren: () => import('./equipments/equipments.module').then(m => m.EquipmentsModule),
      },
      {
        path: 'comunicaciones-mqtt',
        loadChildren: () => import('./mqtt/mqtt.module').then(m => m.MqttModule),
      },
      { path: '', redirectTo: '/map', pathMatch: 'full' },
    ]
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
