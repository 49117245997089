// Angular modules
import { Injectable } from '@angular/core';

@Injectable()
export class ExporterHelper {


  public static getCollectionToExport(col: any[], itemExporter: any): object[] {
    return col.map((item) => this.getExportableItem(item, itemExporter));
  }

  /**
   *
   * @param itemToExport: Entity (normal object) with data
   * @param exporter: Object with an estructure like this:
   *        {
   *           code: {
   *             nameToShow: 'Código'
   *           },
   *           i18n: {
   *             nameToShow: 'Idioma'
   *           },
   *           country: {
   *             name: {
   *               nameToShow: 'País'
   *             }
   *           }
   *        }
   * @returns a plain object { property: value, ... }
   */
  public static getExportableItem(itemToExport: Record<string, any>, exporter: Record<string, any>): object {
    const toret: Record<string, any> = {};

    for (const property in exporter) {
        if (Object.prototype.hasOwnProperty.call(exporter, property)) {
            if (exporter[property].nameToShow) {
                if (Object.prototype.hasOwnProperty.call(itemToExport, property)) {
                    const propertyNameToExport = exporter[property]['nameToShow'];
                    const propertyValueToExport = itemToExport[property];

                    toret[propertyNameToExport] = propertyValueToExport;
                }
            } else {
                const propertyThatIsAnObject = exporter[property];

                for (const p2 in propertyThatIsAnObject) {
                    if (Object.prototype.hasOwnProperty.call(propertyThatIsAnObject, p2) && propertyThatIsAnObject[p2].nameToShow) {
                        const propertyNameToExport = propertyThatIsAnObject[p2]['nameToShow'];
                        const propertyValueToExport = itemToExport[property] ? itemToExport[property][p2] : '';

                        toret[propertyNameToExport] = propertyValueToExport;
                    } else {
                        const propertyThatIsAnObjectLevel2 = propertyThatIsAnObject[p2];

                        for (const p3 in propertyThatIsAnObjectLevel2) {
                            if (Object.prototype.hasOwnProperty.call(propertyThatIsAnObjectLevel2, p3) && propertyThatIsAnObjectLevel2[p3].nameToShow) {
                                const propertyNameToExport = propertyThatIsAnObjectLevel2[p3]['nameToShow'];
                                const propertyValueToExport = itemToExport[property] && itemToExport[property][p2] ? itemToExport[property][p2][p3] : '';

                                toret[propertyNameToExport] = propertyValueToExport;
                            }
                        }
                    }
                }
            }
        }
    }

    return toret;
  }
  // public static getExportableItem(itemToExport: any, exporter: any): object {
  //   let toret: any = {};

  //   for (let property in exporter) {

  //     // exists itemToExport[property].nameToShow => is a single property
  //     // like "code: { nameToShow: 'Código' }"
  //     if (exporter[property].nameToShow) {
  //       if (itemToExport.hasOwnProperty(property)) {
  //         // the entity also has that property
  //         const propertyNameToExport = exporter[property]['nameToShow'];
  //         const propertyValueToExport = itemToExport[property];

  //         toret[propertyNameToExport] = propertyValueToExport;
  //       }
  //     } else {
  //       // is an object property
  //       // like "country { name: { nameToShow: 'País' } }"

  //       const propertyThatIsAnObject = exporter[property];

  //       for (const p2 in propertyThatIsAnObject) {

  //         if (propertyThatIsAnObject[p2].nameToShow) {
  //           {
  //             const propertyNameToExport = propertyThatIsAnObject[p2]['nameToShow'];
  //             const propertyValueToExport = itemToExport[property] ? itemToExport[property][p2]: '';

  //             toret[propertyNameToExport] = propertyValueToExport;
  //           }
  //         }

  //       }

  //     }
  //   }

  //   return toret;
  // }
}
