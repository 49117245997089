// Angular modules
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Internal modules
import { NgbDateCustomParserFormatter } from './datepicker/ngb-date-custom-parser-formatter';


// External modules
import { TranslateModule } from '@ngx-translate/core';
import { NgbActiveModal, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from './components/simplebar/simplebar-angular.module';

// Components
import { ToastComponent } from './components/blocks/toast/toast.component';
import { ProgressBarComponent } from './components/blocks/progress-bar/progress-bar.component';
import { DebugComponent } from '@blocks/debug/debug.component';
import { AlertComponent } from '@blocks/alert/alert.component';
import UploadAttachmentComponent from './components/attachment/upload-attachment/upload-attachment.component';

// Pages
import { BaseListComponent } from './pages/base/base-list.component';

// Datatable
import { CellBasicComponent } from './components/datalist/cells/cell-basic/cell-basic.component';
import { CellCurrencyComponent } from './components/datalist/cells/cell-currency/cell-currency.component';
import { CellDateComponent } from './components/datalist/cells/cell-date/cell-date.component';
import { CellStatusComponent } from './components/datalist/cells/cell-status/cell-status.component';
import { CellUserCardComponent } from './components/datalist/cells/cell-user-card/cell-user-card.component';
import { RowOperationsComponent } from './components/datalist/operations/row-operations/row-operations.component';
import { PaginationComponent } from './components/datalist/pagination/pagination.component';
import { BulkOperationsComponent } from './components/datalist/operations/bulk-operations/bulk-operations.component';
import { HeaderToolsComponent } from './components/datalist/header-tools/header-tools.component';
import { BtnExportComponent } from './components/datalist/btn-export/btn-export.component';


// Forms
import { FormConfirmComponent } from './components/forms/form-confirm/form-confirm.component';

// Modals
import { ModalWrapperComponent } from './components/modals/modal-wrapper/modal-wrapper.component';

// Layouts
import { LayoutHeaderComponent } from './components/layouts/layout-header/layout-header.component';
import { LayoutFooterComponent } from './components/layouts/layout-footer/layout-footer.component';
import { LayoutSidebarComponent } from './components/layouts/layout-sidebar/layout-sidebar.component';
import { PageLayoutComponent } from './components/layouts/page-layout/page-layout.component';
import { LayoutContentComponent } from './components/layouts/layout-content/layout-content.component';

// Directives
import { ModalWrapperDirective } from '@directives/modal-wrapper.directive';
import { ClickOutsideDirective } from '@directives/click-outside.directive';
import { ButtonLoadingDirective } from '@directives/button-loading.directive';
import { CustomAutocompleteComponent } from './components/autocomplete/custom-autocomplete/custom-autocomplete.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LayoutHeaderBackgroundComponent } from '@layouts/layout-header-background/layout-header-background.component';

// Pipes
import { BooleanPipe } from '@pipes/boolean-pipe.pipe';
import { DeviceStatusTypePipe } from '@pipes/device-status-type.pipe';
import { EquipmentPipe } from '@pipes/equipment.pipe';
import { DeviceModeTypePipe } from '@pipes/device-mode-type.pipe';
import { CustomerPipe } from '@pipes/customer.pipe';
import { UserViewDtoPipe } from '@pipes/user-view-dto.pipe';
import { FileSizePipe } from '@pipes/file-size.pipe';
import ChangeLatLngComponent from './components/maps/change-lat-lng/change-lat-lng.component';
import { LanguageSelectorComponent } from './components/languages/language-selector/language-selector.component';
import { DeviceInstallationStateTypePipe } from '@pipes/device-installation-state-type.pipe';
import { DeviceResetTypePipe } from '@pipes/device-reset-type.pipe';

// Autocomplete

@NgModule({
  imports: [
    // Angular modules
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    // SVG Module
    AngularSvgIconModule,

    // Internal modules

    // Pipes
    CurrencyPipe,

    // External modules
    TranslateModule,
    NgbModule,
    SimplebarAngularModule,
  ],
  declarations: [
    // Components
    ToastComponent,
    ProgressBarComponent,
    DebugComponent,
    AlertComponent,
    UploadAttachmentComponent,
    ChangeLatLngComponent,
    LanguageSelectorComponent,

    // Pages
    BaseListComponent,

    // Datatable
    CellBasicComponent,
    CellCurrencyComponent,
    CellDateComponent,
    CellStatusComponent,
    CellUserCardComponent,
    RowOperationsComponent,
    BulkOperationsComponent,
    PaginationComponent,
    HeaderToolsComponent,
    BtnExportComponent,

    // Forms
    FormConfirmComponent,

    // Modals
    ModalWrapperComponent,

    // Layouts
    LayoutHeaderComponent,
    LayoutHeaderBackgroundComponent,
    LayoutFooterComponent,
    LayoutSidebarComponent,
    PageLayoutComponent,
    LayoutContentComponent,

    // Pipes
    BooleanPipe,
    DeviceStatusTypePipe,
    DeviceModeTypePipe,
    DeviceInstallationStateTypePipe,
    DeviceResetTypePipe,
    EquipmentPipe,
    CustomerPipe,
    UserViewDtoPipe,
    FileSizePipe,

    // Directives
    ModalWrapperDirective,
    ClickOutsideDirective,
    ButtonLoadingDirective,
    CustomAutocompleteComponent,

    // Autocomplete
    CustomAutocompleteComponent
  ],
  exports: [
    // Angular modules
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    // Internal modules

    // External modules
    TranslateModule,
    NgbModule,
    SimplebarAngularModule,

    // Components
    ToastComponent,
    ProgressBarComponent,
    DebugComponent,
    AlertComponent,
    UploadAttachmentComponent,
    ChangeLatLngComponent,
    LanguageSelectorComponent,

    // Pages
    BaseListComponent,

    // Datatable
    CellBasicComponent,
    CellCurrencyComponent,
    CellDateComponent,
    CellStatusComponent,
    CellUserCardComponent,
    RowOperationsComponent,
    BulkOperationsComponent,
    PaginationComponent,
    HeaderToolsComponent,
    BtnExportComponent,

    // Forms
    FormConfirmComponent,

    // Modals
    ModalWrapperComponent,

    // Layouts
    LayoutHeaderComponent,
    LayoutHeaderBackgroundComponent,
    LayoutFooterComponent,
    LayoutSidebarComponent,
    PageLayoutComponent,
    LayoutContentComponent,

    // Pipes
    DeviceStatusTypePipe,
    DeviceModeTypePipe,
    DeviceInstallationStateTypePipe,
    DeviceResetTypePipe,
    EquipmentPipe,
    CustomerPipe,
    UserViewDtoPipe,
    FileSizePipe,

    // Directives
    ModalWrapperDirective,
    ClickOutsideDirective,
    ButtonLoadingDirective,

    // Autocomplete
    CustomAutocompleteComponent
  ],
  providers: [
    // External providers
    NgbActiveModal,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },

  ]
})
export class SharedModule { }
