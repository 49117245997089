
export enum DEVICE_RESET_TYPE {

  SIMPLE_RESET = 1,
  FACTORY_RESET = 99,

}

export function getDeviceResetTypes(): { value: number, name: string }[] {

  return Object.keys(DEVICE_RESET_TYPE)
  .filter((key: any) => !isNaN(Number(DEVICE_RESET_TYPE[key])))
  .map((key: any) => {
    return { value: Number(DEVICE_RESET_TYPE[key]), name: key };
  });
}
