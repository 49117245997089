// Angular modules
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostBinding, Inject, Renderer2 } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

// External modules
import { switchMap, map, of, tap, catchError, finalize } from 'rxjs';

// Environment
import { environment } from '@env/environment';

// Elementos del menú
import { IMenuItem } from '@security/models/menu-item.interface';

// Helpers
import { StorageHelper } from '@helpers/storage.helper';
import { UserUtilsHelper } from '@security/helpers/user-utils.helper';

// Enums
import { StorageKey } from '@enums/storage-key.enum';

// Models
import { UserAuthApiDto, UserViewDto } from '@security/models';

// Internal modules
import { StoreService } from '@services/store.service';
import { EventBusService } from '@services/event-bus.service';
import { MenuService } from '@security/services/menu.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';
import { IUserInterfacePrefs } from '@models/user-interface-prefs.interface';
import { BUS_EVENT_TYPE } from '@enums/bus-event-type.enum';
import { EventBusData } from '@models/event-bus-data.model';


@Component({
  selector: 'lockbin-layout-sidebar',
  templateUrl: './layout-sidebar.component.html',
  styleUrls: ['./layout-sidebar.component.scss'],
  animations: [
    trigger('sidebarTrigger', [
      // To add a cool "enter" animation for the sidebar
      // transition(':enter', [
      //   style({ transform: 'translateX(-100%)' }),
      //   animate('300ms ease-in', style({ transform: 'translateY(0%)' }))
      // ]),

      // To define animations based on trigger actions
      state('open', style({ height: '80px' })),
      state('close', style({ height: '0' })),
      transition('open => close', [
        animate('300ms ease-in')
      ]),
      transition('close => open', [
        animate('300ms ease-out')
      ])
    ])
  ]
})
export class LayoutSidebarComponent implements OnInit {

  userViewDto!: UserViewDto;

  public appName: string = environment.appName;
  public isMenuCollapsed = true;
  public isOpen = false;
  public options = { autoHide: true, scrollbarMinSize: 100 };
  public menuItems: IMenuItem[] = [];

  @HostBinding('class.is-compact') isSidebarCompact = true;
  @HostBinding('class.has-hover') isOverSidebar = false;
  @HostBinding('class.nk-sidebar-active') isSidebarActive = false;

  constructor
    (
      private router: Router,
      private elRef: ElementRef,
      private renderer: Renderer2,
      protected storeService: StoreService,
      private eventBusService: EventBusService,
      private menuService: MenuService,
      private customerService: CustomerService,
      private authService: AuthService,
      @Inject(DOCUMENT) private _document: any,
    ) {

    // console.log(this.storeService.getRole());


    // this.menuItems = menuItems;
    // this.menuItems = this.menuService.getMenuByRole(this.storeService.getRole());
    // this.menuItems = this.menuService.getMenuByRole('ROLE_ROOT');


    const role: string = StorageHelper.getItem(StorageKey.ROLE);
    if (role) {
      this.menuItems = this.menuService.getMenuByRole(StorageHelper.getItem(StorageKey.ROLE));
    }

    const userPreferences: IUserInterfacePrefs = StorageHelper.getItem(StorageKey.USER_INTERFACE_PREFS);
    this.isSidebarCompact = (userPreferences && userPreferences.isSidebarCompact) ?? true;

  }

  public ngOnInit(): void {

    // Nos suscribimos al evento de hacer toggle del sidebar
    this.eventBusService.on(BUS_EVENT_TYPE.SIDEBAR_ACTIVE, (event: any) => {

      console.log('SIDEBAR SIDEBAR SIDEBAR SIDEBAR SIDEBAR');


      this.toggleActiveSidebar();
    });

    this.retrieveUserPersistentData();

  }

  private retrieveUserPersistentData() {

    this.storeService.setIsSavingOrRetrievingUserPersistentData(true);

    const userFromMemorySubject = this.storeService.getUser();

    if (userFromMemorySubject && userFromMemorySubject.id>0) {
      this.userViewDto = userFromMemorySubject;
    this.storeService.setIsSavingOrRetrievingUserPersistentData(false);

    } else {
      // user data is NOT saved in Memory (BehaviorSubject)
      const id = StorageHelper.getItem(StorageKey.USER_ID) || 0;

      if (id) {
        // checks if user id is saved in PERSISTENT media (localstorage)
        this.authService.whoami()
          .pipe(
            switchMap((userFromAPI: UserAuthApiDto) => {

                return of(userFromAPI);

              // this.userViewDto = UserUtilsHelper.copyFromUserApiDtoToUserViewDto(userFromAPI);

              // if (this.userViewDto?.customerId > 0) {
              //   return this.customerService.findById(this.userViewDto.customerId)
              //     .pipe(
              //       map(customer => {

              //         this.userViewDto.customer = customer;

              //         return this.userViewDto;
              //       })
              //     );
              // } else {
              //   return of(this.userViewDto);
              // }
            }),
            tap(userFromAPI => {
              this.userViewDto = UserUtilsHelper.transformUserApiDtoToUserViewDto(userFromAPI);
              this.storeService.setUser(this.userViewDto);
              this.storeService.setRole(userFromAPI.roles[0]);

              console.log('----------');
              console.log(this.userViewDto);


            }),
            // catchError(error => {
            //   console.error('Error retrieving user persistent data:', error);

            //   return of(null); // Or handle the error as needed
            // }),
            finalize(() => {
              this.storeService.setIsSavingOrRetrievingUserPersistentData(false);
            })
          ).subscribe();
      } else {
        // nor user data is NOT saved in Memory neither user id is saved in PERSISTENT media => is not possible recover user data
        this.storeService.setIsSavingOrRetrievingUserPersistentData(false);
      }
    }
  }


  /**
   * Establece la variable que asigna la clase CSS en el sidebar
   * @param event Event
   */
  onClickToggleCompactSidebar(event: Event) {
    event.preventDefault();
    this.toggleCompactSidebar();
  }

  toggleCompactSidebar() {
    this.isSidebarCompact = !this.isSidebarCompact;
    // Si no hay preferencias del usuario no se guarda
    const userPreferences: IUserInterfacePrefs = StorageHelper.getItem(StorageKey.USER_INTERFACE_PREFS);
    if (userPreferences) {
      userPreferences.isSidebarCompact = this.isSidebarCompact;
      StorageHelper.setItem(StorageKey.USER_INTERFACE_PREFS, userPreferences);
    }

    console.log('EMITO EVENTO:', this.isSidebarCompact);

    this.eventBusService.emit(new EventBusData(BUS_EVENT_TYPE.SIDEBAR_TOGGLE_COMPACT, this.isSidebarCompact));
  }

  onClickToggleActiveSidebar(event: Event) {
    event.preventDefault();
    this.toggleActiveSidebar();
  }

  toggleActiveSidebar() {
    this.isSidebarActive = !this.isSidebarActive;
    // Modificamos el body de la página para eliminar el scroll.
    this.isSidebarActive ?
      this.renderer.addClass(this._document.body, 'nav-shown') :
      this.renderer.removeClass(this._document.body, 'nav-shown');
  }

  /**
   * Para cuando movemos el ratón encima del sidebar que se despliegue a la izquierda
   */
  mouseOverSidebar() {
    if (this.isSidebarCompact) {
      this.isOverSidebar = true;
    }
  }

  /**
   * Para cuando salimos con el ratón de encima del sidebar
   */
  mouseLeaveSidebar() {
    this.isOverSidebar = false;
  }


  /**
   * Establece el comportamiento cuando hacemos click en un elemento del menú de segundo nivel
   * @param event  MouseEvent
   */
  onClickFirstLevel(event: any) {

    const liMenu = event.target.closest('li.nk-menu-item.has-sub');

    const activeMenus = this.elRef.nativeElement.querySelectorAll('li.nk-menu-item.has-sub.active');
    for (let i = 0; i < activeMenus.length; i++) {
      if (liMenu !== activeMenus[i]) {
        this.renderer.removeClass(activeMenus[i], 'active');
      }
    }

    // Hacemos el toggle de la opción de menú
    if (liMenu.classList.contains('active')) {
      this.renderer.removeClass(liMenu, 'active');
    } else {
      this.renderer.addClass(liMenu, 'active');
    }
  }

  doLogout() {

    this.eventBusService.emit({ name: 'lockbin.logout', value: true });

    this.router.navigate(['/autenticacion']);

  }

  // -------------------------------------------------------------------------------
  // NOTE Init ---------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Actions ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Computed props -----------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Helpers ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Requests -----------------------------------------------------------------
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // NOTE Subscriptions ------------------------------------------------------------
  // -------------------------------------------------------------------------------

}
